import { Component, HostBinding, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  RegistrationApi,
  RegistrationDto,
  RegistrationExamDto,
  RegistrationExamProductDto,
} from '../../../generated/api';
import { Tenant } from '../core/tenant/tenant';
import { TenantService } from '../core/tenant/tenant.service';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { ICONS } from '../shared/fontawesome.module';
import { BottomSheetService } from '../shared/providers/bottom-sheet.service';
import { DialogService } from '../shared/providers/dialog.service';
import { RegistrationDataViewFacade } from '../shared/providers/registration-data.view-facade';
import { RegistrationProcessService } from '../shared/providers/registration-process.service';
import { TrackedComponent } from '../shared/unload-tracker/tracked-component';
import { UnloadTrackerService } from '../shared/unload-tracker/unload-tracker.service';
import { ValidationErrorData } from '../util/form/collect-validation-errors';
import { traverse } from '../util/form/traverse';

@Component({
  selector: 'sx-candidate-view',
  templateUrl: 'candidate.view.html',
  encapsulation: ViewEncapsulation.None,
  providers: [RegistrationDataViewFacade, UnloadTrackerService],
})
export class CandidateViewComponent implements TrackedComponent, OnDestroy {
  @HostBinding('class')
  cssClass = 'registration-process';

  submitted = false;
  registration$: Observable<Partial<RegistrationDto>>;
  backIcon = ICONS.faArrowLeft;
  tenant: Tenant;
  readonly hasSpeakingDate$: Observable<boolean>;
  readonly product$: Observable<RegistrationExamProductDto>;
  readonly validationErrors$: Observable<ValidationErrorData[]>;
  exam: RegistrationExamDto;
  product: RegistrationExamProductDto;

  constructor(
    public process: RegistrationProcessService,
    private facade: RegistrationDataViewFacade,
    private bottomSheetService: BottomSheetService,
    private dialog: DialogService,
    private router: Router,
    private tenantService: TenantService,
    public unloadTracker: UnloadTrackerService,
    private registrationApi: RegistrationApi,
  ) {
    this.process.forms.onCandidate();
    this.product$ = facade.product$.pipe(tap((product) => (this.product = product)));
    this.hasSpeakingDate$ = facade.exam$.pipe(
      map((exam) => exam && !!(exam.speakingDates || []).length),
    );
    this.registration$ = process.registration$;
    this.validationErrors$ = process.forms.candidateErrors$;
    facade.exam$.subscribe((exam: RegistrationExamDto) => {
      this.exam = exam;
    });
    this.unloadTracker.register(this.process.tracker);
    this.tenant = tenantService.tenant;
  }

  onSubmit(event: Event) {
    this.submitted = true;
    const form = this.process.forms.candidate;
    traverse(form, {
      onControl: (control) => {
        control.updateValueAndValidity();
        control.markAsTouched();
      },
    });
    if (!form.valid) {
      return false;
    }

    if (this.product.supportsEro && !form.value.ero.eroSelected) {
      this.dialog
        .openEroDialog({
          eroForm: this.process.forms.ero,
          eroPrice: this.exam.retakeOption.price,
          eroInfoUrl: this.tenant.examRetakeOptionInfoUrl,
          examTypeId: this.exam.examTypeId,
        })
        .pipe(filter((continueToSummary) => continueToSummary))
        .subscribe(() => this.router.navigate(['/', 'summary']));
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    this.router.navigate(['/', 'summary']);
  }

  onShowMobileInfo() {
    this.bottomSheetService.open({
      component: SidebarComponent,
      title: 'Information',
      matIcon: 'info',
      onInstantiated: (component) => (component.onlyShowContact = true),
    });
  }

  ngOnDestroy(): void {
    this.unloadTracker.unregister(this.process.tracker);
  }
}
