import { Component, Input } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-summary',
  templateUrl: 'summary.component.html',
})
export class SummaryComponent {
  @Input() examRetakeOptionSupported = false;
  @Input() examRetakeOptionPrice: number;
  @Input() examSubType: string;
  @Input() hasSpeakingDate: boolean;
  @Input() showSpecialArrangementSection: boolean;
  @Input() examTypeId: string;
  iconTitle = ICONS.faCheckCircle;
  iconSpeaking = ICONS.faMicrophone;
  iconSchool = ICONS.faSchool;
  iconSpecialArrangements = ICONS.faWheelchair;
  iconPersonal = ICONS.faFingerprint;
  iconEro = ICONS.faSync;
  iconBilling = ICONS.faCreditCard;
}
