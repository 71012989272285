<sx-unload-tracker></sx-unload-tracker>
<div class="registration-process__back-link">
  <a [routerLink]="['/', 'list']">
    <fa-icon [icon]="backIcon"></fa-icon>
    {{ 'Back to Exams List' | translate }}
  </a>
</div>

<div class="container">
  <div class="container__two-cells">
    <div class="content-card">
      <sx-steps></sx-steps>

      <div class="content-card__inner">
        <sx-exam [exam]="exam" [selectedProduct]="(product$ | async)?.name"></sx-exam>
      </div>
      <form focusInvalidInput (ngSubmit)="onSubmit($event)">
        <sx-candidate-speaking-test *ngIf="hasSpeakingDate$ | async"></sx-candidate-speaking-test>
        <sx-candidate-retake-option
          *ngIf="product?.supportsEro"
          [examRetakeOptionPrice]="exam?.retakeOption?.price"
          [examTypeId]="exam?.examTypeId"
          [examRetakeOptionInfoUrl]="tenant?.examRetakeOptionInfoUrl"
        ></sx-candidate-retake-option>
        <sx-candidate-personal-data
          [examTypeId]="exam?.examTypeId"
          [examSubType]="exam.examSubType"
        ></sx-candidate-personal-data>
        <sx-candidate-billing-address></sx-candidate-billing-address>
        <sx-candidate-school></sx-candidate-school>
        <sx-candidate-special-arrangements
          *ngIf="tenant.showSpecialArrangementSection"
        ></sx-candidate-special-arrangements>

        <!--      <sx-validation-result [show]="submitted" [errors]="validationErrors$ | async"></sx-validation-result>-->

        <div class="registration-process__action">
          <button mat-button mat-flat-button color="primary" type="submit">
            {{ 'Next' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Sidebar on desktop -->
  <div *isDesktop class="container__one-cell padding-left">
    <sx-sidebar [onlyShowContact]="true"></sx-sidebar>
  </div>

  <!-- Buttons shown on mobile devices only -->
  <div *isBelowDesktop>
    <button
      mat-fab
      color="primary"
      class="mobile-only__fab-button-right"
      aria-label="Information"
      (click)="onShowMobileInfo()"
    >
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>
