<sx-content-card-inner>
  <sx-content-card-section>
    <sx-content-card-title [icon]="iconTitle">{{ 'Summary' | translate }}</sx-content-card-title>
    <sx-content-card-sub-title>{{ 'Summary Description' | translate }}</sx-content-card-sub-title>
  </sx-content-card-section>
  <sx-content-card-section>
    <ng-template [ngIf]="hasSpeakingDate">
      <sx-content-card-section-title [icon]="iconSpeaking">
        {{ 'Speaking Test' | translate }}
      </sx-content-card-section-title>
      <sx-speaking-form></sx-speaking-form>
    </ng-template>
    <div *ngIf="examRetakeOptionSupported" class="margin-top--double">
      <sx-content-card-section-title [icon]="iconEro">
        {{ 'Exam Retake Option' | translate }}
      </sx-content-card-section-title>
      <sx-ero-form [examTypeId]="examTypeId" [price]="examRetakeOptionPrice"></sx-ero-form>
    </div>
    <div class="margin-top--double">
      <sx-content-card-section-title [icon]="iconPersonal">
        {{ 'Personal Data' | translate }}
      </sx-content-card-section-title>
      <sx-personal-data-form
        [examTypeId]="examTypeId"
        [examSubType]="examSubType"
      ></sx-personal-data-form>
    </div>
    <div class="margin-top--double">
      <sx-content-card-section-title [icon]="iconBilling">
        {{ 'Billing Address' | translate }}
      </sx-content-card-section-title>
      <sx-billing-form></sx-billing-form>
    </div>
    <div class="margin-top--double">
      <sx-content-card-section-title [icon]="iconSchool">
        {{ 'School' | translate }}
      </sx-content-card-section-title>
      <sx-school-form></sx-school-form>
    </div>
    <div *ngIf="showSpecialArrangementSection" class="margin-top--double">
      <sx-content-card-section-title [icon]="iconSpecialArrangements">
        {{ 'Special Arrangement' | translate }}
      </sx-content-card-section-title>
      <sx-special-arrangements-form></sx-special-arrangements-form>
    </div>
    <div class="margin-top--double">
      <sx-terms-and-conditions></sx-terms-and-conditions>
    </div>
  </sx-content-card-section>
</sx-content-card-inner>
